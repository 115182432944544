//$inactiveColor: #768875

#breadcrumb {
  background: #2e6797;
  padding: 10px 0 10px 30px;
  width: 100%;

  // attention: following has been disabled earlier but reason is unknow (maybe it was IE8 related?)
  // but we need it to be z-indexed as the sidebar has a top shadow which we intend to 'hide'
  position: relative;
  z-index: 3; }


#breadcrumb > a {
  color: #c5d9e9;
  font-weight: bold; }

#breadcrumb > span {
  color: #c5d9e9; }

.active-crumb {
  font-weight: normal; }

.divider {
  color: #c5d9e9;
  padding: 0 7px; }

#breadcrumb .fa.fa-home {
  font-size: 20px; }
#breadcrumb .fa.fa-home:before {
  vertical-align: middle; }
