// besteht aus 2 elementen links und rechts
.panel-heading {
  // enthaelt die Ueberschrift
  .headline-left {
    float: left; }
  // enthaelt die aktionen wie neu anlegen
  .headline-right {
    padding-top: 15px;
    padding-right: 40px;
    > .btn {
      margin-left: 30px; } } }
.panel-body,.panel-footer {
  // enthaelt die form filter und pagination
  .list-filter {
    .form-inline {
      .form-group {
        padding: 0;
        > label {
          margin-bottom: 0;
          //vertical-align: middle
          input[type="radio"], input[type="checkbox"] {
            vertical-align: middle;
            margin: 0 5px 0 0;
            display: inline-block; } } }
      input {
        // 30px + 2px border
        height: 30px; }
      select {
        height: 30px; }
      .pagination_wrapper {
        label {
          display: block;
          float: left;
          line-height: 32px; } } } } }
