.defintion-list {
  display: table;
  width: auto;

  &>div {
    display: table-row; }
  &>div>div {
    display: table-cell;
    vertical-align: top; }

  .field-label {
    //max-width: 300px
    font-size: 12px;
    padding-right: 20px; }

  .field-value {
    color: #859f79;
    font-size: 12px; } }
