.overview-page {

  h1 {
    font-size: 24px; }

  .panel-header {
    margin: 60px 0 50px; }

  .clickableRow {
    position: relative;
    transform: scale(1); // fixes positioning of the absolute pseudo element for chrome/safari

    a {
      text-decoration: none;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; } } } }

.overview-page .news-list .clickableRow {
  &:hover {
    cursor: pointer; }
  a::before {
    content: none; } }
