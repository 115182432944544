
.btn:focus, .btn:active:focus, .btn.active:focus, a:focus {
  outline: none;
  outline-offset: none;
  text-decoration: none; }


.btn {
  border: none;    // disable button border line

  &.is-small {
    height: 30px;
    padding: 5px 15px; } }

// override bootstrap input-group-btn styling as it messes up with ours ;)
.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
  z-index: auto; }

// classes to override button widths regardless of used context widths
.btn.btn-full-width {
  width: 100% !important; }
.btn.btn-auto-width {
  width: auto !important; }

.btn-primary,
.btn-secondary,
.input-group-btn,
.btn-default,
.btn-default-secondary {
  position: relative;
  @include border-radius($defaultRadius);

  border-color: none;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35); }

// green button styling
.btn-primary,
.btn-secondary,
.input-group-btn {
  @extend .green-gradient; }

// grey button styling (for cancel buttons)
.btn-default,
.btn-default-secondary {
  @extend .grey-gradient; }

.btn-danger {
  @extend .red-gradient; }

.btn-danger,
.btn-default,
.btn-primary {
  height: $bigButtonHeight;
  padding: 10px 16px;
  display: inline-block;
  // vertical align used to align buttons (some have a margin top)
  vertical-align: bottom;

  &:hover, &:active, &:focus {
    color: white; } }



.btn-secondary,
.btn-default-secondary {
  height: $smallButtonHeight;

  &:hover, &:active, &:focus {
    color: white; }

  .fa-plus {
    font-size: 11px;
    position: relative;
    top: -1px;
    padding-right: 5px; } }



.btn-primary,
.btn-secondary {
  &:active {
    background: $guideGreen3; } }
