
// - bootstrap overrides & resets
//    - paddings, margins
//    - panels
//    - tables

// BOOTSTRAP RESETS

.panel-heading {
  @include border-top-left-radius(0px);
  @include border-top-right-radius(0px);
  padding: 1px 0; }

.panel {
  margin-bottom: 0; }


// override column paddings
.col-md-1,.col-md-2,
.col-md-3,.col-md-4,
.col-md-5,.col-md-6,
.col-md-7,.col-md-8,
.col-md-9,.col-md-10,
.col-md-11,.col-md-12 {
  padding-left: 0;
  padding-right: 0; }


// FONT AWESOME RESETS
.fa {
  display: inline; }


body > .col-md-12 {
  float: none; // otherwise body height == header height only
  padding: 0; }


.form-control {
  position: relative;
  @include border-radius($defaultRadius); }





.hasDatepicker {
  z-index: 20; }


// fixes z-index issue (dropdown from .process-navigation nav conflicting z-indexes with dateselect right below)
.panel + .panel-group .panel-default.z1 + .panel.panel-default .hasDatepicker {
  z-index: 0; }


// BO fixes
html {
  -ms-overflow-style: scrollbar; } // prevents ie10+ from auto-hiding the scrollbar

.content {
  .panel-heading {
    &>h4 {
      padding-left: $leftPadding1;
      padding-right: $leftPadding1;
      padding-bottom: 10px; } }
  .filter {
    padding-left: $leftPadding1; }

  .panel-footer {
    padding-left: $leftPadding1; } }
