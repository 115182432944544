
.content-entity {
  padding-left: $leftPadding1;

  fieldset {
    legend {
      font-size: 16px;
      font-weight: bold; }
    > .form-group {
      margin-left: 20px; } }
  .odd {
    background: $tableRowOdd; } }
