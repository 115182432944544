.badge {
  position: relative;
  @include border-radius(10px);
  @include text-shadow(1px 1px 1px rgba(0,0,0,0.3));
  min-height: 21px;
  min-width: 21px; // bootstrap override
  padding: 5px 6px 4px; }

// unfortunately there is another selector which overrides the badge font-size (context: table view), but using !important is not an option
div span.badge {
  font-size: 12px; }

.list-group-item.badges-left-outside {
  position: relative;

  .badge-container {
    left: 0;
    padding: 10px;
    position: absolute;
    text-align: right;
    top: 0;
    width: 50px;

    .badge {
      float: right;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word; } } }

.badge.badge-user {
  @include text-shadow(none);
  @include verticalGradient2(#c0cd23, #a3c302);
  color: #fff;
  float: left;
  margin-right: 10px;
  padding: 4px 6px; }




#menu-profile, #menu-feedback {
  position: relative;

  &:before {
    position: absolute;
    z-index: 1;
    right: 7px;
    top: 8px;
    display: block;
    content: attr(data-count);
    min-width: 20px;
    padding: 0 3px;
    height: 20px;
    border: 2px solid #FFF;
    border-radius: 10px;
    background: red;

    text-align: center;
    color: #FFF;
    line-height: 18px;
    font-size: 10px;

    opacity: 0;
    @include transition(opacity 0.3s ease-in-out); }

  &.has-icon:before {
    opacity: 1; } }
