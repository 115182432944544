$answerMarginLeft: 20px;

.group .group--title {
    font-weight: bold; }

.questionContainer.error > .answerContainer label {
    color: red; }

.answerContainer {

    margin-left: $answerMarginLeft;
    margin-top: 12px;

    &:first-child {
        margin-top: 0; } }

.client-content .client-list form .questionContainer.error select, .has-error select, .error input, .error {
    color: red; }

.question.required em:after {
    content: "*"; }

.question .info {
    padding-left: 10px; }

.document-container {
    h5 {
        font-size: 16px; }
    h6 {
        font-size: 14px; } }

.document-container .fieldset {
    position: relative;

    &.has-legend > .legend + div {
        padding-top: 50px !important;
        margin-top: 0 !important; }

    .legend {
        position: absolute;
        top: 0;
        left: 0; } }

.document-container > .fieldset {

    .question {
        margin: 15px 0; }

    .questionContainer {
        .questionContainer {
            position: relative;
            margin-top: 20px;

            &.has-legend > .legend + div {
                padding-top: 75px !important;
                margin-top: 0 !important; }

            .legend {
                top: 12px;
                padding-left: 20px; }

            .answerContainer {
                margin-left: 0; }

            .question {
                margin-top: 0; } }

        .questionContainer {
            padding: 20px;
            background: rgba(0,0,0,0.05) !important;
            -webkit-print-color-adjust: exact;
            -moz-print-color-adjust: exact; }

        .static_content {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0; }
            .calculation {
                font-weight: bold; } } }

    .remove_instance {
        margin-right: 5px;
        margin-top: 15px; }


    .instance {
        &:last-child {
            border-bottom: none;
            padding-bottom: 10px; } } }

.answer-row {
    .question, .answer {
        display: inline-block; }

    .answer.option-bullet {
        display: list-item;
        margin-left: $answerMarginLeft; }

    .question {
        padding-right: 5px;
        font-weight: bold;
        color: $black;
        margin: 0 !important; } }

.form-inline {
    .form-group {
        label {
            margin-right: 5px;
            margin-left: 15px; } } }

.table.form_collection {
    .form_collection_entry {
        .form-control {
            width: auto; }
        select {
            margin-left: 0; } } }

form {
  counter-reset: instance; }

form {
  .instance {
    counter-increment: instance; }
  .instance::before {
    content: counter(instance)")"; } }


// print

@media print {

    .navbar, #breadcrumb, .sidebar, .ghost-sidebar, .nav, .clientfolder, .sf-toolbarreset, button, .panel-footer {
        display: none !important; }

    .table-row-odd {
        background: $tableRowOdd !important;
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact; }

    .answerContainer, .questionContainer {
        display: block !important;

        a {
            display: none !important; } }

    .answerContainer {
        page-break-inside: avoid; }

    .consultation {
        .form-input {
            width: 100%;

            & + label {
                display: block !important;
                margin-top: 20px; } } }

    input[type="checkbox"], input[type="radio"], select {
        display: none; }

    .radio, .checkbox {
        & > label:before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border: 1px solid #999;
            margin-right: 10px;
            background: #FFF !important; } }

    .radio > label:before {
        border-radius: 50%; }

    .checkbox > label:before {
        border-radius: 3px; }

    input[type="number"], input[type="text"] {
        width: 100% !important;
        max-width: none !important;
        background: #FFF !important;
        border: 1px solid #999;
        color: #FFF !important;
        text-indent: -1000px !important; }

    .select {
        border: 1px solid #999;
        height: 28px;
        border-radius: 3px;
        background: #FFF !important; } }

.jobcenter {
    .add-link {
        margin: 10px 0 0 20px; } }

.step-header .pull-right .step-header--actions {
    margin-right: 20px; }

.math-result.double-underline {
    border-bottom: double;
    left: -0.31em;
    padding: 0 0.31em;
    position: relative; }
