.s-lightbox-trigger {
  border: 0;
  background: transparent;
  float: right;
  padding: 0; }


s-lightbox {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  background-color: rgba(0,0,0,0.6); }


body.s-lightbox-isopen {
  overflow: hidden; }


.s-lightbox__close {
  display: inherit;
  margin: 24px 24px 24px auto;
  border: 0;
  background: transparent;
  padding: 0;
  width: 24px;
  height: 24px;
  background-size: contain;
  position: relative;

  &:after {
    content: "\f00d";
    font-family: fontawesome;
    color: white;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0; } }

