.collection-info {
  padding: 10px 0 10px 30px;
  background: lightblue;

  .collection-info-title {
    padding: 5px 0; }

  .pull-right {
    margin-right: 30px; } }

.collections {
  padding: 25px;

  h4 {
    color: #373737;
    font-size: 18px;
    margin-top: 5px;
    margin-left: 35px;
    font-weight: 500; }

  .sirup-folder {
    padding: 5px;
    color: #fff;
    font-size: 17px;
    float: left;

    background: #a1c200;
    background: -moz-linear-gradient(top, #cfdc26 0%, #c0cd23 5%, #a1c200 95%, #879e09 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #cfdc26), color-stop(5%, #c0cd23), color-stop(95%, #a1c200), color-stop(100%, #879e09));
    background: -webkit-linear-gradient(top, #cfdc26 0%, #c0cd23 5%, #a1c200 95%, #879e09 100%);
    background: -o-linear-gradient(top, #cfdc26 0%, #c0cd23 5%, #a1c200 95%, #879e09 100%);
    background: -ms-linear-gradient(top, #cfdc26 0%, #c0cd23 5%, #a1c200 95%, #879e09 100%);
    background: linear-gradient(to bottom, #cfdc26 0%, #c0cd23 5%, #a1c200 95%, #879e09 100%);
    -pie-background: linear-gradient(#c0cd23, #879e09);

    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }


  .collections-collection {
    font-size: 16px;
    padding: 5px 0 5px 5px;
    border-bottom: 1px solid #d8d8d8;

    a {
      color: #000000; } }

  .collections-collection__active {
    background: #00abd4;
    background: -moz-linear-gradient(top, #00abd4, #3a8db9);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #00abd4), color-stop(100%, #3a8db9));
    background: -webkit-linear-gradient(top, #00abd4, #3a8db9);
    background: -o-linear-gradient(top, #00abd4, #3a8db9);
    background: -ms-linear-gradient(top, #00abd4, #3a8db9);
    background: linear-gradient(to bottom, #00abd4, #3a8db9);

    span {
      color: #ffffff; }

    a {
      color: #ffffff; } }

  .collections-collection-add {
    a {
      color: #939393; } } }

