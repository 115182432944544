
#ui-datepicker-div {
  position: relative;
  @include box-shadow(0 0 5px $shadowColorDark); }


.ui-widget {
  &.ui-datepicker.ui-widget-content {
    background: white;
    border: 1px solid $gray1; }


  &.ui-datepicker {
    font-family: $baseFont;
    th {
      font-weight: normal; }

    a {
      color: $gray4;
      font-weight: normal; }

    a:hover {
      color: $guideBlue1;
      border: 1px solid $guideBlue1; }

    a:active, a:focus {
      background: $guideBlue1;
      color: white;
      border: 1px solid $guideBlue1; }

    // header background
    .ui-widget-header {
      @extend .green-gradient;
      border: none;
      font-weight: normal; }


    // PREV/NEXT ICONS
    .ui-datepicker-next, .ui-datepicker-prev {
      &:hover {
        border: none; }
      &:active, &:focus {
        background: transparent; }


      // HOVER STATE
      &.ui-state-hover {
        background: transparent;
        border: none;

        .ui-icon {
          background-image: url("libs/jquery-ui/images/ui-icons_ffffff_256x240.png"); } }

      &.ui-state-focus {
        .ui-icon {
          background-image: url("libs/jquery-ui/images/ui-icons_ffffff_256x240.png"); } } }

    &.ui-datepicker-prev-hover, &.ui-datepicker-next-hover {
      top: 12px; }
    &.ui-datepicker-next-hover {
      right: 2px;
      // .ui-icon.ui-icon-circle-triangle-e
      //   background-position: -32px -16px
      // .ui-icon.ui-icon-circle-triangle-w
      //   background-position: -96px -16px

      // // HOVER STATE
      // &.ui-datepicker-next-hover
      //   .ui-icon.ui-icon-circle-triangle-w
      //     background-position: -80px -192px
      // &.ui-datepicker-prev-hover
      //   .ui-icon.ui-icon-circle-triangle-e
 }      //     background-position: -48px -192px



    // DAYS
    .ui-state-default {
      background: white;
      border: 1px solid $gray1;

      &.ui-state-active {
        background: $guideBlue1;
        color: white;
        border: 1px solid $guideBlue1; } }

    .ui-state-hover, .ui-widget-content.ui-state-hover, .ui-widget-header.ui-state-hover {
      background: white;
      cursor: pointer; }

    .ui-datepicker-today {
      a {
        color: $guideGreen1;
        border: 1px solid $guideGreen1;

        &:hover {
          color: $guideBlue1;
          border: 1px solid $guideBlue1; }

        &.ui-state-active:hover {
          color: #fff; } } }

    // TIME PICKER
    .ui_tpicker_time_label, .ui_tpicker_time {
      text-align: center; }

    .ui_tpicker_time_label {
      margin-top: 10px; }

    .ui_tpicker_hour_label, .ui_tpicker_minute_label {
      font-weight: normal;
      font-size: 13px;
      margin-top: 10px; }

    .ui-slider {
      height: 8px;
      margin-top: 8px;
      padding: 0 0 0 10px; }

    // handle
    .ui-slider-handle {
      width: 9px;
      height: 22px;
      display: block;
      border: none;
      margin-top: -3px;

      &.ui-state-default, &.ui-state-default.ui-state-active {
        position: relative;
        @include border-radius(2px);
        @extend .green-gradient; }

      &:focus {
        border: none; } }

    // BUTTONS
    .ui-state-default {
      &.ui-datepicker-close, &.ui-datepicker-current {
        @extend .green-gradient;
        color: white;
        font-weight: normal;
        font-family: $baseFont;
        font-size: 14px;
        border: none;

        &:hover {
          color: white; } } }

    .ui-datepicker-buttonpane {
      border: none; }

    .ui-datepicker-month, .ui-datepicker-year {
      //color: #FFF
      color: rgb(51, 51, 51); } } }
