

// -- GENERAL STYLES --
.agency-content {
  position: relative;

  .panel-heading { // ----------------------------------- heading
    border-bottom: 1px solid $gray2;
    overflow: auto;
    padding: 15px 22px 15px 17px;

    &> .fa, &> .sirup {
      @include verticalGradient2($guideBlue1, $guideBlue1GradientStop);
      @extend .icon-circled;
      background-color: $guideBlue1;
      color: white;
      font-size: 16px;
      float: left; }

    h4 {
      float: left;
      padding: 5px 0 0 12px; }

    h3 {
      margin: 0;
      padding-left: 44px; }
    .btn {
      float: right; } }

  .panel-body { // -------------------------------------- body
    padding-left: $leftPadding2; }

  .panel-footer { // ------------------------------------ footer
    padding-left: $leftPadding2; }

  .agency, .consultant-list, .agency-edit { // ---------- shadow outline
    position: relative;
    @include box-shadow( 0 0 5px $shadowColor ); }

  fieldset { // ----------------------------------------- fieldsets
    padding-bottom: 25px;
    legend {
      border: none;
      margin-bottom: 10px; }
    .form-group {
      width: 450px; } }
  .actions {
    float: right;
    a {
      padding-left: 5px; } }



  // -- CONTENT BLOCKS --
  // AGENCY (left side)
  .agency {
    z-index: 2;

    table {
      width: 100%;

      tr {
        border-bottom: 1px solid $gray2;

        td {
          vertical-align: top;
          padding: 10px 23px 10px 10px;

          &:first-child {
            padding-left: $leftPadding2;
            color: $gray8; }

          ul {
            list-style: none;
            margin: 0; } } } } }


  // CONSULTANT LIST (top right)
  .consultant-list {
    z-index: 1;

    .list-group-item {
      padding-left: $leftPadding2;
      padding-right: 25px;

      .fa.fa-user {
        color: $guideGreen3;
        margin-right: 10px; } } }

  .agency-edit {
    z-index: 2; }


  // SUBSIDIARY AGENCIES (bottom leftx)
  .child-agencies {
    .panel-body {
      padding: 0;

      p {                       // empty notification
        padding-left: $leftPadding2;
        padding-top: 25px; }

      .sirup-office {            // icon color
        color: $guideBlue2;
        margin-right: 13px;
        font-size: 14px; }
      .fa-building {
        color: $guideBlue2;
        margin-right: 13px; }

      .actions {
        float: right; }

      &> .list-group {          // first level list-group

        &> .list-group-item {   // first level list-group-item
          padding: 20px 15px 20px $leftPadding1;
          border: none;
          margin: 0;
          position: relative;
          @include border-radius(0);
          border-bottom: 1px solid $gray2;
          .agency-row {
            padding: 10px; }
          .list-group {
            margin: 0;
            .list-group-item {  // subsidiary list-group-items
              padding: 0 0 0 26px;
              border: none; } } } } } }


  // SHOW CONSULTANT
  .consultant {
    .dl-horizontal dt {
      text-align: left;
      font-weight: normal;
      color: $gray8; } } }





// google maps lat/long form fields (create new/edit agency)
#agency_type_latlng_current_position {
  display: inline-block;
  margin: 10px 0; }
#agency_type_latlng_container .input-group .btn {
  height: 30px; } // bootstrap override, has now the same heigth as the corresponding textfield
#agency_type_latlng_container + .input-group {
  margin-top: 10px;
  width: 100%;
  .col-md-6 {
    padding: 0 2px;
    &:first-child {
      padding-left: 0; }
    &:last-child {
      padding-right: 0; } } }
