

#event-new {
  .pull-right {
    float: none !important; }

  .input-group {
    display: block;
    font-size: 12px;
    line-height: 2pt; }

  #event-new_templateId {
    width: 300px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    margin: 0; } }

.event-content {
  .odd {
    background: $tableRowOdd; }

  .panel-heading.content-headline {
    @extend .default-content-headline; }

  .panel-heading {
    h4, h3 {
      padding-left: $leftPadding1;
      margin-bottom: 10px; } }

  .panel-body {
    padding: 20px 15px 20px $leftPadding1; }

  .panel > .panel-body + .table,
  .panel > .panel-body + .table-responsive {
    border-top: 1px solid $gray2; }

  .panel.panel-default .panel-footer {
    padding-left: $leftPadding1;
    padding-top: 30px; }

  .input-group {
    float: left;
    margin-right: 20px;
    width: 300px; }

  .list-group {
    @extend .default-list-group; }


  #event-new_templateId {
    position: relative;
    @include border-radius($defaultRadius); }


  form {
    @extend .default-form;

    .error-quick-links {
      padding-top: 0;
      padding-bottom: 20px; }

    .form-group {
      &> div {
        padding: 10px $leftPadding1; }

      label > input[type="text"] {
        margin-left: 15px; } }

    .template-checkbox {
      padding-left: $leftPadding1;
      padding-top: 20px;

      label {
        display: inline;
        margin-left: 8px; }

      input {
        float: left;
        margin-top: 1px; } }

    // event form specific
    .toggle-content-action .form-group > div > label,
    .toggle-content .form-group > div > label {
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 10px;
      margin-top: 10px; }

    .toggle-content.closed {
      display: none; }
    .toggle-content.opened {
      display: block; }

    .form-group .mce-container {
      max-width: 450px; }

    .form-element-indent .form-group > div {
      &> label + * {
        margin-left: 20px; } }

    .time-input-combo {
      font-size: 20px;

      input {
        font-size: 14px;
        margin: 0 4px;
        text-align: center; }

      input:first-child {
        margin-left: 0; } } } }
