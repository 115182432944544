.download-list {
  list-style: none;
  padding-left: unset;

  li {
    margin-bottom: 3rem;
    padding-right: 30px;

    a {
      color: #000000;
      text-decoration: none;
      display: flex;

      &::after {
        position: absolute;
        font-family: fontawesome;
        content: "\f019";
        right: 0;
        color: #00abd4;
        font-size: 20px; } } } }
