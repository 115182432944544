.detail-page {
  margin-top: 80px;

  time {
    font-weight: bold;
    display: block;
    margin-top: 50px; }

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 5px; }

  h2 {
    font-size: 22px; }

  h3 {
    font-size: 20px; }

  h4 {
    font-size: 18px; }

  h5 {
    font-size: 16px; }

  h6 {
    font-size: 14px; }

  p, ul, ol, pre, blockquote {
    margin-bottom: 30px;

    a {
      text-decoration: underline;

      //&::after
 } }      ////content: ' >'

  ul, ol {
    padding-left: 1em; }

  .back-link {
    padding: 0 15px;
    text-align: center;
    margin-bottom: 3rem; }

  .detail-page-headline {
    margin-top: unset; }

  .detail-page-aside-row {
    margin-bottom: 30px;
    border-bottom: 1px solid #333;

    .aside-row-headline {
      font-weight: bold;
      margin-top: unset; }

    ul, ol {
      list-style: none;
      padding-left: unset; } } }
