.faq-show-main {
  .detail-page-headline {
    margin-bottom: 3rem; } }

.faq-show-aside {
  @media all and (min-width: 992px) {
    margin-left: 3rem !important; }
  .aside-row-headline {
    margin-bottom: 2rem; }

  .image-carousel {
    max-width: 380px;

    .swiper {
      height: 200px;
      width: 100%;
      max-width: 400px; }

    .swiper-slide-image-wrapper {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: beige;

      img {
        max-width: 100%;
        max-height: 100%; } } }

  .faw-swiper-caption-pagination-wrapper {
    margin-top: 1rem;
    margin-bottom: 3rem;
    display: flex;

    .faq-swiper-caption {
      flex-grow: 1; }

    .faq-swiper-pagination-container {
      position: relative;
      display: flex;
      align-items: center; }

    .swiper-pagination {
      position: relative;
      padding: 0 10px; }

    .swiper-pagination-fraction {
      bottom: 0 !important; }

    .swiper-button-next, .swiper-button-prev {
      height: fit-content;
      position: relative;
      top: unset;
      margin-top: unset;
      left: 0;
      right: 0;

      &:after {
        font-size: 12px; } }

    .swiper-button-prev:after {
      font-family: fontawesome;
      content: '\f054';
      color: black; }

    .swiper-button-next:after {
      font-family: fontawesome;
      content: '\f053';
      color: black; } } }

.swiper-item-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @media (hover: hover) {
    &:hover {
      background-color: rgba(0,0,0,0.4);
      cursor: pointer;

      &:after {
        content: '\f002';
        font-family: fontawesome;
        font-size: 40px;
        color: white;
        z-index: 10;
        opacity: 1; } } } }

.s-faq-detail.detail-page {
  .row {
    margin: 0 15px; } }
