.s-back-to-top {
  opacity: 0;
  z-index: 99999;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  transition: opacity 0.5s ease-out;

  .s-back-to-top__link {
    @extend .btn-primary;
    width: 3.75rem;
    height: 3rem; }

  .s-back-to-top__link--triangle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6.5px 9px 6.5px;
    border-color: transparent transparent #fff transparent;
    transition: all .2s ease; } }
