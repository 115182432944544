.profile-content {

  //
  .panel-heading {
    h4, h3 {
      padding-left: $leftPadding1;
      margin-bottom: 10px; }

    &.content-headline {
      @extend .default-content-headline;

      a {
        margin: 8px 0 15px 50px; } } }

  .panel-body {
    padding: 0; }

  .panel-footer {
    padding-left: $leftPadding1; }

  .odd, .odd legend {
    @extend .table-row-odd; }

  fieldset {
    padding: 20px 0;

    &> legend {
      padding-left: $leftPadding1;
      padding-top: 20px;
      margin: 0;
      border: none; }

    .form-group {
      padding: 0 $leftPadding1; } }


  // DESCRIPTION LIST
  .dl-horizontal {
    dt, dd {
      padding: 1px 0; }

    dt {
      padding-left: $leftPadding1;
      text-align: left;
      width: 220px; }

    dd {
      li {
        list-style: none;

        // &:before
 } }        //   content: '- '

    .options {
      padding-left: 20px;

      a {
        padding-right: 10px; } } }

  .list-group {
    @extend .default-list-group; }


  // "TERMINE"
  .no-appointments {
    padding-left: $leftPadding1; } }
