s-lightbox {
    .s-item__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;

        @media all and (max-width: 500px) {
            width: 80%; } }

    .s-item__media {
        width: min-content;
        max-width: 100%;
        height: fit-content;
        margin: 0 auto;
        display: block;
        overflow-y: scroll;

        // Scrollbar
        // Firefox
        scrollbar-width: none;
        // Scrollbar browsers using webkit
        &::-webkit-scrollbar {
          width: 0;
          display: none; }

        .s-item__img {
            width: unset;
            max-height: 70vh;
            max-width: 80vw; } }

    .s-item__media-caption,
    .s-item__media-copyright {
        color: white;
        margin-bottom: 0; } }

