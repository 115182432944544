
$tableError: rgba(255,102,102,0.7);
$tableWarning: rgba(255,255,102,0.7);
$tableSuccess: rgba(102,255,102,0.7);

.content.content-table {
  width: 100%;
  position: relative;
  display: table;

  .panel-body {
    padding-left: 50px; }

  .table {
    > tbody {
      > tr {
        &.danger {
          background-color: $tableError; }
        > td {
          &.danger {
            background-color: $tableError; }
          &.success {
            background-color: $tableSuccess; }
          &.warning {
            background-color: $tableWarning; } } } } } }

.panel.z1 {
  z-index: 1;
  position: relative;
  overflow: visible; }

.table-cell {
  display: table-cell;
  width: auto; }
