// CLICKABLE ROW
.clickableRow {
  a .clickableRow__name-with-toggle {
    color: #333333; }

  &:hover {
    background: $tableRowOddHover;
    color: white;

    a, div, .text-warning, .text-danger, .text-success, a .clickableRow__name-with-toggle {
      color: white; }

    &.table-row-odd {
      background: $tableRowOddHover; } }

  &.pointer {
    cursor: pointer; }

  &.nonclickable:hover {
    cursor: default;
    color: initial;
    background: initial; } }

.collapsibleRow {
  td:first-of-type {
    width: 100%; } }


$row-hierarchy-spacing: 20px;
$row-hierarchy-depth: 6;

$colorWidth: 4px;

$turquoise: rgba(66,139,202,1);

.records_list {
  @for $hierarchy from 1 through $row-hierarchy-depth {
    $padding: $hierarchy * $row-hierarchy-spacing;
    @if $hierarchy < 6 {
      .hierarchy-deep-#{$hierarchy} {
        padding-left: $padding; }
      .clickableRow__name-depth-#{$hierarchy} {
        max-width: calc(100% - (35px + #{$padding})); }
      .collapsibleRow.clickableRow-hierarchy-deep-#{$hierarchy} {
        $gray: rgba(0,0,0,calc(0.08 * (#{$hierarchy} * 0.7)));
        $colorChangePosition: $colorWidth * ($hierarchy + 1);
        background: linear-gradient(90deg, $turquoise, $turquoise $colorChangePosition, $gray calc(#{$colorChangePosition} + 1px), $gray 100%);
        &:hover {
          background: $tableRowOddHover; } } }
    @else {
      .hierarchy-deep-max {
        padding-left: $padding; }
      .clickableRow__name-depth-max {
        max-width: calc(100% - (35px + #{$padding})); }
      .collapsibleRow.clickableRow-hierarchy-deep-max {
        $gray: rgba(0,0,0,calc(0.08 * (#{$hierarchy} * 0.6)));
        $colorChangePosition: $colorWidth * ($hierarchy + 1);
        background: linear-gradient(90deg, $turquoise, $turquoise $colorChangePosition, $gray calc(#{$colorChangePosition} + 1px), $gray 100%); } } }


  .clickableRow__name-without-toggle {
    margin-left: 38px;
    display: inline-block; }

  .clickableRow__name-with-toggle {
    display: inline-block;
    vertical-align: sub; }

  .clickableRow.collapse.in {
    display: table-row; }

  .clickableRow-hierarchy-deep-0 {
    $gray: rgba(0,0,0,0.08);
    $turquoise: rgba(66,139,202,1);
    $colorWidth: 4px;
    background: $gray;
    background: linear-gradient(90deg, $turquoise, $turquoise $colorWidth, #fff calc(#{$colorWidth} + 1px), #fff 100%);
    &:hover {
      background: $tableRowOddHover; } }


  .clickableRow__toggleLink {
    text-decoration: none;
    display: inline-block;

    &:before {
      font-family: 'Glyphicons Halflings';
      font-size: 14px;
      text-decoration: none;
      padding: 5px 10px 5px 10px;
      display: inline-block;
      vertical-align: top; }

    &.collapsed:before {
      content: "\e080"; }

    &.collapsed:after {
      display: none; }

    &:before {
      content: "\e114"; } } }
