.widget.news {
  .panel-body {
    padding: 0; }

  .panel-heading {
    @include verticalGradient2($guideBlue1, $guideBlue1GradientStop);

    a {
      color: white; }

    .fa {
      background: none; } }

  .list-group-item {
    border-bottom: 1px solid #ddd;
    border-left: none;
    border-right: none;
    border-radius: 0; }

  .panel-footer {
    border-bottom: 1px solid #ddd; } }
