.loader {
  display: none\9; }

.loader, .loader * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none; }

.loader {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.loader:after {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-animation: rotate 0.6s linear infinite;
  -moz-animation: rotate 0.6s linear infinite;
  -ms-animation: rotate 0.6s linear infinite;
  -o-animation: rotate 0.6s linear infinite;
  animation: rotate 0.6s linear infinite;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border-top: 2px solid #545a6a;
  border-bottom: 2px solid #d4d4db;
  border-left: 2px solid #545a6a;
  border-right: 2px solid #d4d4db;
  content: '';
  opacity: .5; }

@keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
    -webkit-transform: rotateZ(-360deg);
    -moz-transform: rotateZ(-360deg);
    -o-transform: rotateZ(-360deg); }

  100% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg); } }

@-webkit-keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
    -webkit-transform: rotateZ(-360deg);
    -moz-transform: rotateZ(-360deg);
    -o-transform: rotateZ(-360deg); }

  100% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg); } }

@-moz-keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
    -webkit-transform: rotateZ(-360deg);
    -moz-transform: rotateZ(-360deg);
    -o-transform: rotateZ(-360deg); }

  100% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg); } }

@-o-keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
    -webkit-transform: rotateZ(-360deg);
    -moz-transform: rotateZ(-360deg);
    -o-transform: rotateZ(-360deg); }

  100% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg); } }
