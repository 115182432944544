.faq-intro {
	max-width: 600px;
	margin-bottom: 35px; }

.faq-filter {
	margin-bottom: 25px;

	& > * {
		display: inline-block; }

	select {
		margin-right: 20px; } }

.faq-toggle {
	padding: 12px 20px;
	cursor: pointer;

	span:before {
		padding-right: 5px;
		content: '\f107'; }

	&.collapsed span:before {
		padding-right: 11px;
		content: '\f105'; } }

.faq-pagination {
	overflow: hidden; }

.faq-content {
	padding: 0 20px 20px 20px; }

.faq-entry {
	position: relative;

	h2 {
		font-size: 24px;

		&:first-child {
			margin-top: 0; } } }

.faq-answer {
	margin-bottom: 25px; }

.faq-video-link {
	span {
		padding-right: 5px; } }

.faq-to-top {
	position: absolute;
	top: 12px;
	right: 20px;
	cursor: pointer;
	opacity: 0.2;
	@include transition(opacity 0.3s ease-in-out);

	&:hover {
		opacity: 1; } }

.faq-attachments-links {
	margin-top: 50px; }
