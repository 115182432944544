


// FONTS
$baseFont: Arial, Helvetica, sans-serif;
$baseFontSize: 14px;
$headerFontSize: 18px;
$bigHeaderFontSize: 24px;


// COLORS
// styleguide
$guideBlue1: #00abd4;                // hover, tooltips
$guideBlue1_inactive: #a6e1ef;       // hover text inactive
$guideBlue1GradientStop: #3A8DB9;    // target blue for navheader gradient
$guideBlue2: #098eb5;                // links, icons
$guideBlue3: #0f799e;                // 2.gradient color

$guideGreen1: #cfdc26;               // logo
$guideGreen2: #c0cd23;
$guideGreen3: #a1c200;
$guideGreenIcons: #a1c200;           // icons

$tableRowOdd: #dcede2;               // odd table rows color
$tableRowHover: #f6f7f6;             // table row even hover color
$tableRowOddHover: #2cbee3;          // table row odd hover color

$gray1: #eeeeee;
$gray2: #dddddd;
$gray3: #cccccc;
$gray4: #bbbbbb;
$gray5: #aaaaaa;
$gray6: #999999;
$gray7: #888888;
$gray8: #777777;

$buttonSeperatorLight: white;
$buttonSeperatorDark: black;


// top navigation
$darkBlue: #137695;
$lightBlue: #54c1dc;

// submenu navigation
$subnavColor: #c7d8c1;
$subnavHoverColor: #b9c1ad;

// other
$black: #373737;                     // headlines, breadcrumb active
$darkgray: #555555;
$shadowColor: #bbbaaa;
$shadowColorDark: #7b7b7b;
$errorColor: red;
$subHeaderColor: #e7f2dc;                 // background color for area between top navbar and content area (e.q. searchbox on dashboard)

$modalDangerColor: #e47474;
$modalSuccessColor: #b4c04e;

// OFFSETS AND SIZES
$leftPadding1: 50px;
$leftPadding2: 60px;
$defaultRadius: 3px;
$bigButtonHeight: 40px;
$smallButtonHeight: 30px;
$sidebarWidth: 380px;
$shadowOffset: 10px;
$defaultInputWidth: 450px;




// MIXINS
// custom gradient mixin
@mixin verticalGradient2($startColor, $endColor) {
  background: $startColor;
  background: -moz-linear-gradient(top,  $startColor, $endColor);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,$startColor), color-stop(100%,$endColor));
  background: -webkit-linear-gradient(top,  $startColor, $endColor);
  background: -o-linear-gradient(top,  $startColor, $endColor);
  background: -ms-linear-gradient(top,  $startColor, $endColor);
  background: linear-gradient(to bottom,  $startColor, $endColor); }

@mixin horizontalGradient3($color1, $color2, $color3) {
  background: $color1;
  background: -moz-linear-gradient(left,  $color1 0%, $color2 50%, $color3 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,$color1), color-stop(50%,$color2), color-stop(100%,$color3));
  background: -webkit-linear-gradient(left,  $color1 0%,$color2 50%,$color3 100%);
  background: -o-linear-gradient(left,  $color1 0%,$color2 50%,$color3 100%);
  background: -ms-linear-gradient(left,  $color1 0%,$color2 50%,$color3 100%);
  background: linear-gradient(to right,  $color1 0%,$color2 50%,$color3 100%); }


@mixin fourCornerRadius($topLeft, $topRight, $bottomRight, $bottomLeft) {
  border-top-left-radius: $topLeft;
  border-top-right-radius: $topRight;
  border-bottom-right-radius: $bottomRight;
  border-bottom-left-radius: $bottomLeft;
  border-radius: $topLeft $topRight $bottomRight $bottomLeft; }






// BASIC SETTINGS
// background gradient
body {
  font-family: $baseFont;
  background: white;
  overflow-x: hidden; }

.clear-right {
  clear: right; }

.clear-left {
  clear: left; }

.clear {
  clear: both; }

.text-green {
  color: $guideGreen3; }

// green gradient
.green-gradient {
  background: #a1c200;
  background: -moz-linear-gradient(top,  #cfdc26 0%, #c0cd23 5%, #a1c200 95%, #879e09 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cfdc26), color-stop(5%,#c0cd23), color-stop(95%,#a1c200), color-stop(100%,#879e09));
  background: -webkit-linear-gradient(top,  #cfdc26 0%,#c0cd23 5%,#a1c200 95%,#879e09 100%);
  background: -o-linear-gradient(top,  #cfdc26 0%,#c0cd23 5%,#a1c200 95%,#879e09 100%);
  background: -ms-linear-gradient(top,  #cfdc26 0%,#c0cd23 5%,#a1c200 95%,#879e09 100%);
  background: linear-gradient(to bottom,  #cfdc26 0%,#c0cd23 5%,#a1c200 95%,#879e09 100%);
  -pie-background: linear-gradient(#c0cd23, #879e09);

  &:hover {
    background: $guideGreen3; } }

// grey gradient (for cancel buttons)
.grey-gradient {
  @include verticalGradient2(#787a77, #646368);

  &:hover, &:active {
    background: #646368; } }

.red-gradient {
  @include verticalGradient2(#d2322d, darken(#d2322d, 10)); }

.sirup {
  font-size: 18px; }


// GENERAL BOOTSTRAP RESETS
a, a:hover, a:active, a:focus, .s--link {
  color: $guideBlue2; }

.panel, .panel-group .panel {
  border-radius: 0; }

.panel-default > .panel-heading {
  background: white; }


.panel-heading > h4 {
  background: white;
  padding-top: 20px;
  padding-left: 15px;
  font-size: 18px;
  margin: 0; }

.panel-heading .padding-h4 {
  padding-top: 20px;
  margin: 0; }

.panel-heading .padding-right {
  padding-right: 50px; }

label.required:after {
  content: '*'; }

input[type="text"], input[type="email"], input[type="password"] {
  position: relative;
  @include border-radius($defaultRadius);
  border: 1px solid $gray4;
  height: 30px;
  padding-left: 5px; }

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield; }

select.form-control {
  padding-top: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  padding-left: 4px; }

.form-control {
  line-height: 1; // for IE, if that is conflicting in other browsers - find a better solution then
  max-width: $defaultInputWidth;
  height: 28px;
  // padding: 4px 4px 0 4px

  &.select2-container {
    padding: 0; } }

.filter {
  .s-date-filter {
    .s-date-filter-element-text {
      margin-top: 6px;
      line-height: 110%; }
    .s-date-filter-element {
      width: auto !important;
      float: left;

      .s-date-filter-element-select {
        width: auto; } } } }

.vich-image {
  .form-control {
    padding: 3px;
    height: 30px; } }

.input-group {
  max-width: 450px; }

.input-group .btn {
  height: 28px;
  padding: 0 5px; }

// -- EXTENDABLES ----------------
// -> icon circular background
$circle: 30px;
.icon-circled {
  position: relative;
  @include border-radius(50%);
  text-align: center;
  vertical-align: middle;
  height: $circle;
  width: $circle;
  line-height: $circle;
  font-size: 19px;
  float: left; }


.horizontal-gradient {
  @media all and (max-width: 1280px) {
    background: url(../../images/sprites/bg1280.png) repeat-y;
    @include horizontalGradient3( #cde6d5, #f8f6c6, #e4f1e1 ); }

  @media all and (min-width: 1281px) and (max-width: 1920px) {
    background: url(../../images/sprites/bg1920.png) repeat-y;
    @include horizontalGradient3( #cde6d5, #f8f6c6, #e4f1e1 ); } }



.table-row-odd {
  background: $tableRowOdd; }


// used for headlines with a border underneath (e.g. client-view, event-view)
.default-content-headline {
  border-bottom: 1px solid $gray3;
  display: inline-block;
  margin-bottom: 30px;
  padding: 10px 0 5px;
  width: 100%;

  a {
    margin-bottom: 8px; } }


// used for list-group lists (e.g. event-view)
.default-list-group {
  .list-group-item {
    padding-left: $leftPadding1;
    border: none;

    ul {
      list-style: disc inside;
      padding-left: 10px;
      padding-top: 5px;

      li {
        padding: 1px 0;
        background: transparent; } }

    .question, .answer {
      display: inline-block;
      vertical-align: top; }
    .question {
      width: 50%;
      padding-right: 40px; }

    .answer {
      ul {
        list-style: none;
        padding: 0;
        margin-left: -3px;

        li:before {
          content: '- '; } } } } }




.default-error-list {
  list-style: none outside none;
  margin: 0;
  padding: 5px 10px 0 0;
  color: $errorColor; }


.relative-form-groups {
  .form-group {
    position: relative; } }


.default-form {
  .form-group {
    margin-bottom: 0;
    padding: 5px 0; }

  .panel-body {
    padding: 0; }

  h4 > span {
    font-size: $headerFontSize; }

  // ERROR QUICKLINKS
  .error-quick-links {
    list-style: none outside none;
    padding-left: 50px;
    padding-top: 20px;

    li {
      padding: 1px;

      a {
        color: $guideBlue2;

        &:before {
          font-family: fontawesome;
          content: '\f105  '; } } } }

  .error select, .error input {
    color: $black; } }

.default-fieldset {}
// used for "dokubogen" and event edit
.default-fieldset {
  clear: both;

  legend {
    padding-left: $leftPadding1;
    border: none;           // bootstrap reset
    margin-bottom: 0;
    overflow: hidden; }       // fixes webkit issue

  .odd {
    @extend .table-row-odd; }

  &> div {
    padding: 10px $leftPadding1 20px $leftPadding1; }
  &.no-border > div {
    padding: 0; }
  .form-control {
    width: 450px;
    height: 28px;
    padding-bottom: 0;
    padding-top: 1px; }

  textarea.form-control {
    max-width: 450px;
    min-width: 450px;
    height: auto;
    min-height: 60px; }

  .questionContainer {
    select {
      margin-left: 20px; } } }

.default-arrow-after {
  content: "\f105";
  font-family: fontawesome;
  font-size: 21px;
  float: right;
  right: 10px;
  top: 12px;
  position: absolute; }




.default-dropdown-menu {
  li {
    border-top: 1px solid $gray1;
    &:first-child {
      border-top: none; }

    // dropdown list icons
    i {
      margin-right: 10px;
      color: $guideBlue1; }

    &.active i {
      color: white; }

    &> a {
      padding: 8px 20px; } }


  // active list item
  &> .active > a {
    background-color: white;
    border-left: 3px solid $guideBlue1;
    padding-left: 17px;
    color: $black;

    &> i {
      color: $guideBlue1; } }

  &> li > a:hover, &> li > a:focus, &> .active > a:hover, &> .active > a:focus {
    background-color: $guideBlue1;
    color: white;
    &>i {
      color: white; } } }

// alert messages
.alert {
  margin: 20px; }

header .alert-container {
  .alert-warning {
    border-color: #8a6d3b; }
  .alert-success {
    border-color: #3c763d; }
  .alert-danger {
    border-color: #a94442; } }

// bootstrap col layout override (necessary for agency view which column layout looks identical to dashboard but isn't)
.sub-col-full-width > div {
  width: 100%; }

.mb-15 {
  margin-bottom: 15px; }
