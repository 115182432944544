$filter_text_color: $darkgray;
$filter_border_color: #B9B9B9;

$filter_border_color: #e2e3db;
$filter_range_button_active: #8E8E8E;
$filter_range_button_inactive: #CCC;

.statistics-filter {
    padding: 25px; }

.statistics-filter--agency {
    max-width: 320px;
    min-width: 222px; }



.statistics-filter--favourite {
    margin-bottom: -10px;
    padding: 10px 22px 0 22px;
    position: relative;
    z-index: 1;


    .statistics-filter--range-button {
        font-weight: normal;
        cursor: pointer;

        input {
            display: none; }

        span {
            padding: 5px 12px;
            border: 1px solid $filter_range_button_inactive;
            color: $filter_range_button_inactive; }

        input:checked + span, &:hover span {
            background: $filter_range_button_active;
            border-color: $filter_range_button_active;
            color: #FFF; } } }

.statistics-filter--submit {
    margin-top: 20px; }

.statistics-filter--header {
    padding: 15px !important;
    background: #c5d7c1;

    .select2-container {
        padding: 0;
        width: 320px; }

    .statistics-filter--pickers, .statistics-filter--submit {
        display: none; }

    .statistics-filter--range {
        display: block;
        float: right;
        cursor: pointer;

        & > * {
            display: inline-block; } }

    .statistics-filter--range-label {
        color: $filter_text_color; }

    .statistics-filter--range-input {
        margin: 0 10px;
        padding: 4px 10px;
        border: 1px solid $filter_border_color;
        border-radius: 4px;
        background: #FFF; }

    .statistics-filter--range-icon {
        position: relative;
        top: 3px;
        color: $filter_text_color;
        font-size: 22px; } }

.statistics-filter--pickers {
    margin-top: 20px;

    .statistics-filter--picker {
        margin-bottom: 10px; }

    .change-trigger {
        margin-top: 5px;
        width: 224px; } }

.statistics-filter--modal {
    padding: 25px 40px 50px 40px;

    .statistics-filter--range {
        display: none; }

    .statistics-filter--agency {
        display: none; }

    .statistics-filter--range-settings {
        margin-bottom: 45px; }

    .statistics-filter--range-setting {
        margin-right: 45px;

        &:last-child {
            margin-right: 0; }

        input {
            margin-right: 4px; }

        span {
            font-weight: normal; } }

    .statistics-filter--pickers {
        margin-bottom: 50px;
        @include clearfix; }

    .statistics-filter--picker {
        display: block;
        float: left;
        clear: left;
        margin-bottom: 15px;

        div {
            margin-bottom: 5px; }

        input {
            font-weight: normal; }

        &:last-child {
            margin-bottom: 0; } } }
