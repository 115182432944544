

// bootstrap overrides
.navbar {
  margin: 0; }

.navbar.navbar-default {
  border: none; }

.navbar {
  @include border-radius(0px); }



//--> HEADER
header {
  cursor: default;

  .navbar-default {
    @include verticalGradient2($guideBlue1, $guideBlue1GradientStop); }


  .container-fluid {
    @include verticalGradient2($guideBlue1, $guideBlue1GradientStop);
    position: relative;
 }    //@include box-shadow( 0 0px 3px #878672)


  // text color and shadow effect
  .navbar-default .navbar-nav > li > a,
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus,
  .navbar-default .navbar-text {
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); }

  // active and mouseover state
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > .current_ancestor > a,
  .navbar-default .navbar-nav > .current_ancestor > a:hover,
  .navbar-default .navbar-nav > .current_ancestor > a:focus,
  .navbar-nav > li:hover,
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:focus,
  .navbar-default .navbar-nav > .open > a:hover {
    background-color: $guideBlue2;
    color: white; }

  // seperators between tabs
  .navbar-nav > li {
    position: relative;
    border-right: 1px solid $darkBlue;
    border-left: 1px solid $lightBlue;
    &.last {
      border-right: none; }
    &.first {
      border-left: none; }

    &.list-logo {
      // border-right: 1px solid $darkBlue
      border-left: none; } }


  .navbar-nav.navbar-left > li.first {
    border-left: 1px solid $lightBlue; }

  .navbar-nav.navbar-left + .navbar-left {
    border-left: 1px solid $darkBlue; }


  // PROFILE DROPDOWN LIST
  .dropdown-menu {
    @extend .default-dropdown-menu; }




  // logout icon
  .navbar-nav > li > a.dropdown-toggle {
    padding-right: 42px; }
  .sirup-usersettings {
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    &+ .caret {
      display: none; } } }

//<-- header







//--> SUB MENU
.sub-menu {
  width: 100%;

  // general padding
  li {
    a {
      padding: 15px 20px; } }

  &>li {
    border-right: 1px solid white;   // white seperators
    &.last {
      border: none; } }


  // IDLE STATE
  background: $subnavColor;
  a, &.navbar-default .navbar-nav > li > a {
    color: $darkgray; }


  // ACTIVE TAB
  // default view
  .active-tab,
  .current_ancestor,
  &.navbar-default .navbar-nav > .active > a {
    background: white;
    color: $black;
    cursor: default;
    // z-index: 0
    position: relative;
    @include box-shadow(0 0 6px $shadowColorDark);

    &:hover,&:focus {
      background: white;
      color: $black; } }

  // client view
  .active {
    a {
      @extend .active-tab; } }


  // HOVER AND FOCUS STATES
  // default view
  &.navbar-default .navbar-nav > li > a {
    &:hover, &:focus {
      background-color: $subnavHoverColor;
      color: white; } }

  // client view
  &.nav .open > a, &.nav .open > a:hover, &.nav .open > a:focus {
    background-color: $subnavHoverColor;
    color: white; }
  &.nav {
    &> li {
      &.active > a {
        @extend .active-tab; }
      &>a {
        &:hover,&:focus {
          background-color: $subnavHoverColor;
          color: white; } }

      &.current_ancestor > a:focus, &.current_ancestor > a:hover {
        background: white;
        color: $black; } } }

  .current_ancestor:after {
    background: none repeat scroll 0 0 white;
    content: "";
    height: 11px;
    margin-left: -7px;
    position: absolute;
    width: 125px; }


  // sub-menu first tab left padding
  &> .first, &> ul> .first {
    padding-left: $leftPadding1; }


  // SUBMENU DROPDOWN
  .dropdown-menu {
    @include box-shadow(0 0 2px $shadowColor);

    &> li {
      &> a {
        padding: 5px 15px;

        &:hover, &:focus {
          background: $guideBlue1;
          color: white; } }

      &.active > a {
        @include box-shadow(none);
        border-left: 2px solid $guideBlue1;

        &:hover {
          border-left: 2px solid $guideBlue1; } } } } }

//<-- submenu

// no hover for username in navbar
header .navbar-text {
  @include verticalGradient2($guideBlue1, $guideBlue1GradientStop);
  margin: 0;
  padding: 15px; }
