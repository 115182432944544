
.overall {
  overflow: hidden; }


// CLIENT VIEW HEADER AREA
.clientfolder .panel-heading,
.clientfolder.panel,
#masterdocument {
  //@extend .horizontal-gradient
  background: $subHeaderColor; }

.sub-header {
  z-index: 3; }



.clientfolder {
  // "Stammdaten bearbeiten"
  .actions {
    float: right;
    padding-right: $leftPadding1;

    .btn-group {
      @include border-radius($defaultRadius);
      &> a {
        @include fourCornerRadius($defaultRadius, 0, 0, $defaultRadius);
        padding-top: 11px;
        border-right: 2px solid #819a03;

        &:hover, &:active, &:focus {
          color: white; } } }

    .dropdown-toggle {
      @include fourCornerRadius(0, $defaultRadius, $defaultRadius, 0);
      padding: 0 15px;
      @include box-shadow(-1px 0px 0px #cede4b);
      left: 1px; }

    .open .dropdown-toggle.btn-primary {
      background: $guideGreen3; }

    .dropdown-menu {
      li:hover, li>a:hover {
        background: $guideBlue1;
        color: white; } } }



  .panel-heading {
    padding: 0 26px; }
  .panel-heading:hover {
    color: $black; }



  // icons
  .panel-heading .fa, .panel-heading .sirup {
    @include verticalGradient2(#c0cd23, #a3c302);
    @extend .icon-circled;
    color: white; }

  h1 {
    font-size: 26px;
    padding-left: 10px;
    margin: 0;
    float: left; }

  .panel-heading {
    padding-top: 20px; }
  .panel-body {
    clear: both;
    padding: 0 0 20px 65px; }


  // toggle link
  .toggle {
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;

    span {
      padding: 0; } }

  // caret
  span.fa-caret-down:after {
    content: "";
    height: 40px;
    margin-left: -23px;
    margin-top: -10px;
    position: absolute;
    width: 40px; }
  .collapsed .fa-caret-down:before {
    content: "\f0da"; }    // replace icon
  .fa-caret-down {
    cursor: pointer;        // use hand cursor
    padding-right: 5px; } }



// COLLAPSABLE INFO BOX
#masterdocument {
  background-size: 150%;

  .list-group-item {
    background: transparent;
    padding-left: 66px;
    border: none;
    display: table;
    width: 100%;

    .question, .answer {
      display: table-cell; }
    .question {
      width: 40%; }

    &.table-row-odd {
      background: url(../../images/sprites/tableRowOdd10.png);
      background-repeat: repeat; } }

  .seperator {
    border-left: 1px solid $gray7;
    margin: 0 10px; }



  .list-group:first-child,
  .list-group-item:first-child {
    border-top: 0 none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

  .list-group-footer {
    padding: 20px 15px 40px 66px; } }








// CLIENTFOLDER CONTENT AREA
.client-content,
.documentation-content {
  clear: both;
  width: 100%;
  position: relative;
  display: table;

  select, textarea, .input-extension {
    position: relative;
    @include border-radius($defaultRadius);
    border-width: 1px;
    border-color: $gray4;
    font-size: 14px;
    padding: 0 0 0 4px;
    width: auto; }


  span, li, a {
    font-size: $baseFontSize; }

  // accordion
  .accordion-toggle {
    cursor: pointer; }

  #note-accordion,
  #note-widget-accordion {
    .accordion-toggle span:before {
      content: '\f107'; } // override default fa icon
    .accordion-toggle.collapsed span:before {
      content: '\f105'; } } // default fa icon

  .panel-heading {
    &>h4 {
      padding-left: $leftPadding1;
      padding-bottom: 10px; }

    &>a {
      margin: 15px 0 15px $leftPadding1; }

    &.small-headline {
      padding-bottom: 30px; } }


  // "Kennung" und Datum
  .client-list, .documentation-content-inner {
    height: 100%;
    width: auto;
    display: table-cell;

    .z1 {
      z-index: 1;
      position: relative;
      overflow: visible; }
    .z4 {
      z-index: 4;
      position: relative;
      overflow: visible; }

    &> .panel > .panel-body {
      padding-left: $leftPadding1; }

    .panel-body.contains-document {
      padding: 0; }

    .panel-heading.content-headline {
      @extend .default-content-headline; }


    //buttons
    .btn-primary {
      margin-right: 3px;
      margin-top: 10px; }
    .btn-list {
      .btn-primary {
        margin-right: 0;
        margin-top: 0; } }

    .input-group {
      width: 300px;
      margin-right: 20px;
      float: left;

      input {
        position: relative;
        @include fourCornerRadius($defaultRadius, 0, 0, $defaultRadius);
        height: $smallButtonHeight;
        border-right: none; }

      .input-group-btn {
        position: relative;
        @include fourCornerRadius(0, $defaultRadius, $defaultRadius, 0);
        height: $smallButtonHeight; } }





    // PROCESS STEPS
    .process-navigation {
      //border-bottom: 1px solid $gray3
      cursor: default;
      margin-bottom: 20px;
      padding: 15px $leftPadding1 0;

      .nav-pills {
        margin-top: -1px;
        li {
          margin-top: 1px;
          &+li {
            border-left: 1px solid $gray5;
            margin-left: 0; }
          .process-step {
            position: relative;
            @include border-radius(0);
            background: $gray2;
            color: $black;
            display: inline-block;
            padding: 14px 10px;
            vertical-align: bottom {
 }              // padding: 5px 0
            &.inactive {
              color: $gray7 !important; } }

          &.active {
            //border-bottom: 2px solid $guideBlue2

            .process-step {
              background: #fff;
              color: $guideBlue2; }
            .process-step.inactive {
              background: #fff !important;
              color: $guideBlue2 !important; }
            //&> a:hover, &> a:focus, &> a:active,
            //&.dropdown.open > a
 }            //  border-bottom: none
          // hover state
          &:hover {
            //border-bottom: 2px solid $black

            .process-step {
              background: #fff;
              color: $black; }
            .process-step.inactive {
              background: $gray2; } } }

        .dropdown {
          &.open {
            .dropdown-menu {
              @extend .default-dropdown-menu;
              //
              // @include fourCornerRadius(0, 0, $defaultRadius, $defaultRadius)

              li {
                border-left: none;
                margin: 0;
                //&.active
                //  border-bottom: none

                //&> a:hover
 } } } } } }                //  border-bottom: none








    .panel-group .panel + .panel {
      margin-top: 0; }

    // "Bildungsberatung", "Unterstuetzungsberatung"
    form {
      @extend .default-form;

      .error-list {
        @extend .default-error-list; }

      .consultation {
        padding: 0 $leftPadding1 20px;
        border-bottom: 1px solid $gray3;

        label, .form-input {
          display: inline-block;
          vertical-align: top; }

        label {
          display: inline;
          top: 10px; }

        .form-input {
          margin-right: 30px;

          li:before {
            content: "* "; } }

        #Bildungsberatung_process {
          margin-right: 20px; }


        select, input {
          height: 24px; }

        input {
          @extend .input-extension; } } }

    // "BERATUNGEN" SEARCH FIELD
    .search-consultancy {
      select {
        float: left;
        height: 30px;
        position: relative;
        @include fourCornerRadius($defaultRadius, 0, 0, $defaultRadius);
        border-right: 2px solid $gray3;
        padding: 4px; }

      .input-group {
        float: left;
        input {
          position: relative;
          @include border-radius(0);
          padding: 4px; } } }


    // Termine -> Termin anlegen
    .clientevent-new {
      .panel-body {
        padding-left: $leftPadding1; }

      .form-group {
        padding-top: 15px; }

      .panel-footer {
        padding-top: 30px; }

      #event_start, #event_end {
        left: 115px;
        position: absolute;
        margin-top: -3px; } } }



  .panel.panel-default {
    #Bildungsberatung_process,
    #Unterstuetzungsberatung_process {
      float: left;
      .form-group {
        margin-bottom: 0; } }



    .panel-footer {
      padding-left: $leftPadding1; } }



  .list-group {
    @extend .default-list-group; }

  // table fieldsets (dokubogen)
  fieldset, .fieldset {
    @extend .default-fieldset;

    .questionContainer {
      color: $black;
      h4 {
        color: $black; } } }

  label {
    margin-right: 10px; } }




// SIDEBAR
.ghost-sidebar {
  display: table-cell;
  width: $sidebarWidth - 2px;

  &.collapsed {
    width: 47px; } }


.sidebar {
  position: absolute;
  float: right;
  right: 0;
  margin: 0;
  top: 0;
  width: $sidebarWidth;
  background: white;
  z-index: 2;
  @include box-shadow( 0 0 $shadowOffset $shadowColor );

  &.collapsed {
    overflow: hidden;
    width: 47px;
    & > div {
      width: $sidebarWidth; }

    // place an invisible overlay over the collapsed content (so you actually can't click/hover the stuff underneath)
    .sidebar-toggle {
      position: relative;
      z-index: 1; }
    &:after {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; } }

  & > div {
    border-bottom: 3px solid $gray2;

    &:first-child, &.no-border-bottom {
      border-bottom: none; } }



  // PANEL HEADING
  .panel-heading {
    display: inline-block;
    width: 100%;

    // inactive
    .icon-header, a {
      display: inline-block;
      position: relative;
      font-size: 18px;
      margin: 0;
      width: 100%;
      padding: 14px 12px 12px $leftPadding1; }

    // active
    a {
      color: $black;
      &:hover {
        text-decoration: none;
        background-color: $guideBlue1;
        color: white;
        &:after {
          @extend .default-arrow-after;
          top: 10px; } } }

    .fa, .sirup {
      @include verticalGradient2(#c0cd23, #a3c302);
      @extend .icon-circled;
      color: white;
      // background: $guideGreen2
      position: absolute;
      left: 12px;
      top: 12px; } }

  .panel.panel-default > .panel-body {
    padding-left: $leftPadding1; }


  .sidebar-toggle {
    background: none repeat scroll 0 0 $subnavColor;
    height: 50px;

    .fa {
      color: $black;
      display: inline-block;
      font-size: 20px;
      margin-left: 5px;
      margin-top: 9px;
      padding: 10px; }

    .fa:hover {
      cursor: pointer; } }


  // lightgray list item seperator
  .list-group .list-group-item {
    border-top: 1px solid $gray2;
    padding-left: $leftPadding1;
    border-top-width: 1px; //bootstrap override
    &:last-child {
      border-bottom: none; }

    &:hover {
      background: $guideBlue1;
      color: white;

      a, .history-entry-headline, .consultant {
        color: white; }


      // muted text
      p, div {
        color: $guideBlue1_inactive; } }


    h5 {
      padding: 8px;
      margin: 0; }

    p {
      padding-left: 0;
      color: $gray6; } }



  form > .panel-body {
    padding-left: $leftPadding1;

    & > div {
      border-top: 1px solid $gray2;
      padding-top: 10px;

      &:first-child {
        border: none; } } }



  // WIDGETS
  #calendar-widget {
    .panel-footer {
      text-align: center;
      padding: 0 0 40px 0; }

    .calendar_slider {
      padding-top: 20px;

      ul {
        margin-bottom: 0; } } }

  // there should be a line above "neue notiz" button if element before is not collapsed
  #note-widget .list-group-item .in.collapse:after {
    background: $gray2;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%; }

  #history-widget {
    .history-entry-headline {
      width: 50%; } }


  .panel.panel-default .panel-footer {
    padding-left: $leftPadding1; }

  #export-widget {
    .date-from-to {
      .form-group {
        display: table;

        label {
          display: table-cell;
          width: 21%; }

        input {
          display: table-cell; } } } } }

//.sidebar


// simple headline (currently just used for some widgets)
.sub-headline {
  color: #373737; }

// small text (currently just used for some widgets)
.small-text {
  font-size: 12px;
  p {
    font-size: 14px; } }
