$widget_border_color: #e2e3db;

$widget_add_color: #CCC;
$widget_add_border_color: #f6f6f6;
$widget_text_color: #373737;
$widget_icon_color: #676767;
$widget_fav_color: #f0cb01;




.widget-form--public {
    padding-bottom: 10px; }

.widget-from--public-options {
    margin-left: 20px; }

.widget-form--sections {
    padding-top: 20px;

    label {
        padding-bottom: 10px; }

    .collection-element {
        position: relative; } }

.widget-form--section {
    margin: 0 -40px 20px -40px;
    padding: 10px 40px 20px 40px;
    border-bottom: 1px solid #CCC;

    &:last-child {
        border: none;
        padding-bottom: 1px; } }

.widget-from--section-remove {
    position: absolute;
    right: -20px;
    top: 50%;
    margin-top: -15px;
    text-decoration: none;

    .fa {
        font-size: 30px;
        color: $widget_icon_color; }

    &:hover {
        text-decoration: none;

        .fa {
            color: $widget_text_color; } } }

.widget-form--section-add {
    display: inline-block;
    margin-left: 20px; }


.statistics-widget {
    .statistics-indicator-content {
        display: inline-block;
        position: relative;
        text-align: center;
        font-family: 'HelveticaNeue', 'Helvetica', 'Arial', sans-serif; }

    .statistics-indicator-value {
        font-size: 74px;
        font-weight: bold;
        line-height: 1em;
        white-space: nowrap; }

    .statistics-indicator-divider {
        width: 100%;
        margin: 10px 0 12px;
        border-top: 2px solid; }

    .statistics-indicator-label {
        font-size: 16px;
        line-height: 1.5em;
        display: inline-block; }

    .statistics-widget--option {

        a {
            display: inline-block;
            padding: 4px 8px;
            border-radius: 4px;
            background: #e3e3e3;
            text-decoration: none;
            font-size: 13px;
            color: #3284a5;
            @include transition(background 0.3s ease-in-out);

            &:hover {
                background: darken(#e3e3e3, 5); } } }

    .google-visualization-table {
        min-width: 100%; }
    .google-visualization-table-table {
        min-width: 100%;
        height: unset !important; } }

.statistics-widget {

    &.is-create, &.is-public {
        background: $widget_add_border_color;

        svg {
            & > rect {
                fill: $widget_add_border_color; }

            & > g > rect {
                fill: #FFF; } } }

    &.is-favourite {
        .statistics-widget--label, .statistics-widget--option.is-fav {
            .fa-star {
                color: $widget_fav_color; } } }

    &.is-partial:not(.is-create) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }

    &.is-partial {
        position: relative;
        overflow: hidden;
        margin: 0 22px;
        padding: 22px 0;
        height: 300px;
        border-right: 1px solid $widget_border_color;
        border-bottom: 2px solid $widget_border_color;

        .statistics-widget--option, .statistics-widget--chart-type {
            width: 33.333%;
            padding-left: 0;
            padding-right: 0;

            &:last-child {
                text-align: right; }

            &:first-child {
                text-align: left; } }

        .statistics-widget--label {
            z-index: 1;
            width: 100%;
            padding: 0 20px;
            left: 0; }

        .listening-chart {
            width: 100%;
            //top: 50%
            //left: 0
            //padding: 0 20px
            margin: 40px 0 0 0 !important; }

        .statistics-widget--options {
            position: absolute;
            bottom: 20px;
            z-index: 1;
            width: 100%;
            padding: 0 20px;
            left: 0;
            opacity: 0;
            @include transition(opacity 0.3s ease-in-out); }

        &:hover {
            .statistics-widget--options {
                opacity: 1;
                @include transition(opacity 0.3s ease-in-out 0.3s); } }

        .statistics-widget--option {
            display: none;

            &:last-child {
                padding-right: 0;
                text-align: right; }

            &:first-child {
                text-align: left; }

            &.is-details {
                display: block; }

            &.is-note {
                display: block; }

            &.show-on-hover {
                display: block; } } }

    &.is-full {
        .statistics-widget--label {
            padding: 25px;
            font-size: 22px;
            .big {
                font-size: 22px; } }
        .statistics-widget--label-icon {
            top: 0;
            font-size: 28px; }

        .statistics-widget--options {
            padding: 0 20px; }

        .statistics-widget--option {
            padding-top: 10px;
            padding-bottom: 10px; }

        table {
            margin: 0 auto;
            min-width: 500px;
            line-height: 3em;
            width: 100%;
            th {
                background: #c5d7c1;
                font-size: 18px; }

            th, td {
                text-align: center !important; } }

        .statistics-indicator-chart {
            text-align: center;
            padding: 100px 0 150px 0; } } }


.statistics-widget--create-link {
    position: relative;
    display: block;
    height: 100%;
    border: 3px dashed $widget_add_color;
    @include transition(border-color 0.3s ease-in-out);

    background: #FFF;
    border-radius: 15px;
    text-decoration: none !important;


    &:hover {
        border-color: darken($widget_add_color, 15);

        .statistics-widget--create-label {
            color: darken($widget_add_color, 15); } } }


.statistics-widget--create-label {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -53px;

    color: $widget_add_color;
    @include transition(color 0.3s ease-in-out);
    text-align: center; }

.statistics-widget--create-text {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold; }

.statistics-widget--create-icon {
    font-size: 120px;
    line-height: 60px;
    height: 60px; }



.statistics-widget--label {
    font-size: 13px;
    color: $widget_text_color; }

.statistics-widget--label-icon {
    position: relative;
    top: 2px;
    margin-right: 3px;
    font-size: 22px;

    .fa-star {
        color: #CCC;
        text-decoration: none;

        &:hover {
            color: $widget_fav_color; } } }





.statistics-widget--options {
    margin-top: 10px;
    overflow: hidden; }

.statistics-widget--option {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
        color: $widget_text_color;

        .fa {
            color: $widget_text_color; } }

    .fa {
        color: $widget_icon_color;
        margin-right: 3px;
        font-size: 16px; }

    &.is-fav:hover {

        .fa-star {
            color: $widget_fav_color; } } }


.statistics-widget--chart-type {
    float: left;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
        text-decoration: none;
        color: $widget_text_color;

        .fa {
            color: $widget_text_color; } }

    &:last-child {
        padding-right: 0; }

    .fa {
        color: $widget_icon_color;
        font-size: 16px; }

    &.is-inactive {
        opacity: 0.5;
        color: $widget_icon_color !important;
        cursor: default;

        .fa {
            color: $widget_icon_color !important; } } }


.dashboard-content {
    .statistics-widget {
        &.is-create, &.is-public {
            background: #FFF;

            svg {
                & > rect, & > g > rect {
                    fill: #FFF; } } } } }

svg > g > g:last-child {
    pointer-events: none; }

@media (max-width: 840px) {
    .statistics-widget--options {
        text-align: center;

        .pull-left, .pull-right {
            display: inline-block;
            float: none !important; } } }
