s-lightbox {
    .s-swiper {
        display: flex;
        height: 100%; }

    .s-swiper__slide {
        display: flex; }

    .s-swiper__pagination {
        position: absolute;
        color: white;
        top: 24px;
        left: 24px;
        width: unset;
        bottom: unset;

        @media all and (min-width: 500px) {
            bottom: 24px;
            top: unset; } }

    .s-swiper__prev,
    .s-swiper__next {
        display: none;
        border: none;
        background: transparent;

        @media all and (min-width: 800px) {
            display: block;
            flex-shrink: 0;
            background-size: contain;
            width: 48px;
            height: 48px; }

        &::after {
            display: none; } }

    .s-swiper__prev {
      left: 24px;

      &:after {
        content: "\f053";
        display: block;
        font-family: fontawesome;
        color: white;
        font-size: 34px; } }

    .s-swiper__next {
      right: 24px;

      &:after {
        content: "\f054";
        display: block;
        font-family: fontawesome;
        color: white;
        font-size: 34px; } } }

