$tooltipColor: #333;

.tooltip {
  .tooltip-inner {
    background-color: $tooltipColor;
    margin: -0.4px; } // fixes weird offset issue with arrow

  &.top .tooltip-arrow {
    border-top-color: $tooltipColor; }
  &.right .tooltip-arrow {
    border-right-color: $tooltipColor; }
  &.bottom .tooltip-arrow {
    border-bottom-color: $tooltipColor; }
  &.left .tooltip-arrow {
    border-left-color: $tooltipColor; } }
