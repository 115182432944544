@mixin clearfix {
    &:after {
        content: '';
        display: block;
        height: 0;
        line-height: 0;
        visibility: hidden;
        clear: both; } }

@mixin border-radius($radius...) {
    border-radius: $radius; }

@mixin border-top-left-radius($radius) {
    border-top-left-radius: $radius; }

@mixin border-top-right-radius($radius) {
    border-top-right-radius: $radius; }

@mixin box-shadow($args...) {
    box-shadow: $args; }
@mixin transition($args...) {
    transition: $args; }
@mixin text-shadow($args...) {
    text-shadow: $args; }
