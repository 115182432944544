.feedback-show {
  .journals {
    margin: 0;
    padding: 0;
    .journal {
      .journal-heading {
        margin-top: 20px;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
        overflow: auto;
        .journal-user {
          font-weight: bold;
          float: left; }
        .journal-date {
          float: right; } }
      .journal-note {
        margin-top: 10px;
        clear: both; } } } }

#feedback_create_message {
  min-height: 130px; }
