$noteWidth: 220px;
$borderColor: #cccccc;

// disable list bullet
.slider-list-entry {
  list-style: none outside none;
  display: none; }

.slider-list-entry.active {
  display: block; }


// no padding
.slider-list {
  padding: 0;
  position: relative; }


// calendar note container
.calendar-event-big {
  border: 1px solid $borderColor;
  position: relative;
  @include border-radius(5px, 5px);
  margin: 20px auto 30px;
  width: $noteWidth;
  color: $black;
  padding-bottom: 15px; }


// note-holder graphics rendered as divs
.note-holder {
  background-color: $borderColor;
  border: 2px solid white;
  box-sizing: content-box;
  height: 18px;
  position: relative;
  top: -12px;
  width: 6px; }


#left-note-holder {
  float: left;
  left: 14%; }

#right-note-holder {
  left: 81%; }


.calendar-event > div {
  text-align: center; }



// day
.calendar-event-big .day {
  font-size: 44px;
  font-weight: bold;
  line-height: 35px;
  padding: 10px 0; }


// month
.calendar-event-big .month {
  font-weight: bold;
  font-size: 13px; }


// time
.calendar-event-big .time {
  font-size: 13px;
  padding: 2px 0 10px; }


// buttons
.leftButton, .rightButton {
  height: 18px;
  width: 12px;
  position: absolute;
  top: 72px;
  cursor: pointer; }


.leftButton {
  background-image: url("../../images/sprites/leftArrows.png"); }


.leftButton:hover {
  background-image: url("../../images/sprites/leftArrows.png");
  background-position: 0 -18px; }


.rightButton {
  background-image: url("../../images/sprites/rightArrows.png"); }


.rightButton:hover {
  background-image: url("../../images/sprites/rightArrows.png");
  background-position: 0 -18px; }


// calendar footer
.panel-footer {
  background-color: white;
  border-bottom-left-radius: none;
  border-bottom-right-radius: none;
  border-top: none; }



// calendar "Weitere Termine"
.calendar-event.calendar-event-small {
  clear: both;
  padding: 0 0 0 $leftPadding1; }


.calendar-widget .panel-footer {
  padding: 0; }

.calendar-event-big .title {
  padding: 0 12px; }

.calendar-event-empty {
  height: 150px;

  .title {
    padding-top: 45px; } }




// calendar widget on dashboard
.dashboard-content #calendar-widget {
  .day-day {
    font-size: 16px;
    margin-left: $leftPadding1;
    margin-right: $leftPadding1;

    // divider line
    &~.day-day {
      border-top: 1px solid $gray2;
      padding-top: 10px; } }

  // more spacing between list and button
  .calendar-event + .panel-footer {
    margin-top: 20px; }

  .rest {
    margin-bottom: 20px; } }



// calendar/appointment additional
.calendar-event-big {
  .appointment-additional {
    display: block;
    text-align: center; } }

.calendar-event-small {
  &.clickableRow:hover span {
    color: #fff; }
  .appointment-additional {
    color: $gray8;
    display: block; }
  .client-name,
  .event-type {
    font-size: 12px; } }

// appointment list in main content (due to selector inheritance for sidebar context which doesn't include sidebar selector, we need to get rid of left paddings)
ul.schedule-group-appointments .calendar-event-small,
.panel .list-group .list-group-item ul.schedule-group-appointments {
  padding-left: 0; }
