@import "modules/mixins";

/* partials */
@import "modules/basics";
@import "modules/badge";
@import "modules/button";
@import "modules/pagination";
@import "modules/layout";
@import "modules/login";
@import "modules/modals";
@import "modules/select2";
@import "modules/navbar";
@import "modules/dashboard";
@import "modules/breadcrumb";
@import "modules/tables";
@import "modules/definition-list";
@import "modules/client-view";
@import "modules/event-view";
@import "modules/profile-view";
@import "modules/agency-view";
@import "modules/newclient";
@import "modules/calendar";
@import "modules/datepicker";
@import "modules/tooltips";
@import "modules/clickable-row";
@import "modules/feedback";
@import "modules/dokubogen";
@import "modules/faq";
@import "modules/statistics-filter";
@import "modules/statistics-widget";
@import "modules/sirup-icons";
@import "modules/loader";
@import "modules/popover";
@import "modules/layout_table";
@import "modules/layout_list_page";
@import "modules/layout_show";
@import "modules/report";
@import "modules/statistics-collection";
@import "modules/to_top";
@import "modules/multi_file_upload";
@import "modules/widget_export";
@import "modules/font_icons";
@import "modules/process";
@import "modules/hindrance";
@import "modules/widget_news";
@import "modules/overview-page";
@import "modules/detail-page";
@import "modules/text-color";
@import "modules/download-list";
@import "modules/faq-show";
@import "modules/lightbox/lightbox";
@import "modules/lightbox/lightboxItem";
@import "modules/lightbox/lightboxSwiper";
@import "modules/faq-list";
