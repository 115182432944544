.faq-list {
  .pagination_wrapper {
    padding-top: 20px;

    .pagination {
      float: left;
      margin-right: 0px; }
    .form-group {
      display: inline-flex;
      align-items: center;
      label {
        margin-bottom: 0;
        margin-right: 10px; } } }

  .spacer {
    display: block;
    background: none repeat scroll 0 0 #c7d8c1;
    height: 50px;
    width: 100%; }

  .search {
    margin-bottom: 40px;

    input[type="search"] {
      padding: 10px 10px 10px 20px;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid lightgray;
      min-width: 40%; }
    button[type="submit"] {
      left: 0;
      margin-left: -5px;
      top: 0;
      height: 42px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;

      &:after {
        content: '\f002';
        font-family: fontawesome;
        display: block; } } }

  .panel-body {
    padding-left: 0 !important;
    padding-right: 0 !important; }

  .search, .faq-entry {
    padding-left: 50px; }

  .faq-entry {
    padding-top: 30px;
    padding-bottom: 30px;
    cursor: pointer;
    display: flex;

    h4 {
      font-weight: bold;
      font-size: 20px; }

    .fa.fa-arrow-circle-right.text-green {
      padding-right: 20px;
      display: flex;
      align-self: flex-end;
      flex-grow: 1; }
    .faq-answer {
      margin-bottom: 0 !important; }

    .faq-question-answer-wrapper {
      @media all and (min-width: 992px) {
        max-width: 830px; } } } }

.faq-siderbar-content-wrapper {
  padding: 30px;

  .headline-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-bottom: 30px;

    .fa.fa-sliders {
      background-color: rgb(161, 194, 0);
      border-radius: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      &:before {
        font-size: 18px; } } }

  #entry_filter {
    .form-group {
      position: relative;

      div.closed {
        display: none; }

      label {
        font-size: 14px;
        padding-right: 20px;

        &:after {
          display: none;
          content: ''; } }
      ul {
        padding: 0;
        list-style: none;

        li {
          display: flex;
          justify-content: space-between;

          label {
            font-weight: normal; }

          input {
            margin-right: 14px; } } } }
    .form-group-toggle {
      position: absolute;
      right: 0;
      top: 0;
      height: 24px;
      background: transparent;
      border: none;

      &.closed {
        transform: rotate(180deg); } } } }

.faq-support-headline {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.42857143;
  color: #333; }

.faq-support-wrapper {
  padding: 30px;
  border-bottom: none !important;

  a {
    margin-top: 12px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center; } }
