// flex-box
.asFl {
  display: flex;
  align-items: flex-start;

  @media all and (max-width: 1023px) {
    display: block; } }

.asJustifyContentCenter {
  justify-content: center; }

.asJustifyContentBetween {
  justify-content: space-between; }

.asAlignItemsEnd {
  align-items: flex-end; }

.isInvisible {
  visibility: hidden;

  @media all and (max-width: 1023px) {
    display: none; } }

.asFullWidth {
  width: 100%;
  max-width: none; }

.asTextCenter {
  text-align: center; }

.asBanner {
  padding: 20px 0; }

.asRow {
  &.asAlignItemsEnd {
    .asCol {
      &:last-child {
        max-width: 582px; } } }

  .asCol {
    padding: 10px 0;
    width: 25%;

    &:first-child {
      max-width: 18vw; }

    &:last-child {
      width: 50%; }

    @media all and (max-width: 1023px) {
      padding-bottom: 0;
      width: 100%;
      max-width: none; } } }

.asCol {
  label {
    font-weight: normal; }

  .radio,
  .checkbox {
    margin-top: 0;

    input {
      height: auto !important; } }

  .radio + .radio,
  .checkbox + .checkbox {
    margin-top: 0; }

  .default-error-list,
  .client-content .client-list form .error-list,
  .client-content .documentation-content-inner form .error-list,
  .documentation-content .client-list form .error-list,
  .documentation-content .documentation-content-inner form .error-list,
  .new-client form .error-list {
    padding-top: 0; } }

.asColorBase {
  color: rgba(55, 55, 55, 1);
  background-color: #eee; }

.asColorLight {
  color: rgba(55, 55, 55, 0.6); }

.asBorderBottomLess {
  border: none !important; }

.asEven {
  background-color: $tableRowOdd; }

.asWrapper {
  padding: 20px 0;

  ~ button {
    margin-top: 20px; }

  @media all and (max-width: 1023px) {
    padding: 0 0 20px 0; } }
