.multiple_file_upload {
  > div {
    width: 100%;
    height: 40px;
    margin: 5px 0;
    clear: both; }

  > div:not(:last-child) {
    border-bottom: 1px solid #cccccc; } }

#add_attachment {
  margin-top: 10px; }
