@mixin transparentBackground($color, $transparency) {
  background: rgba($color, $color, $color, $transparency); }

.modal {
  @include transparentBackground(0, 0.4);

  // close button
  .close {
    font-size: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    z-index: 1;
    span[aria-hidden] {
      display: block;
      width: 100%; } }

  .modal-dialog {
    position: relative;
    @include border-radius(4px);
    //@include box-shadow(0 0 5px $shadowColor)
    //width: 40%
    margin: 14% auto 20px;

    // display date
    .date-time {
      display: block; }

    // set z-index to bootstrap modal z-index
    .hasDatepicker {
      z-index: 1050; }

    .modal-content {
      min-height: 57px;
      overflow: hidden;

      .panel-heading {
        background: $gray5;
        color: #fff;
        padding: 30px 100px;
        position: relative;

        h4 {
          background: none;
          font-size: 20px;
          padding: 0;
          //position: relative

          .fa {
            display: inline-block;
            font-size: 40px;
            left: 30px;
            margin-top: -20px; // element height / 2
            position: absolute;
            top: 50%; } } }

      .panel-danger .panel-heading {
        background: $modalDangerColor; }
      .panel-success .panel-heading {
        background: $modalSuccessColor; }

      .panel-body, .is-panel-body {
        padding: 25px 100px 50px;

        .list-group-item {
          border: none;
          background-color: transparent;
          padding: 10px 0; }

        .form-group {
          .date-time {
            position: relative;
            @include border-radius($defaultRadius); }

          .form-control {
            border: 1px solid $gray6;
            max-width: 100%;
            min-width: 100%;
            width: 100%; }
          .form-control.s-date-filter-element-select {
            border: 1px solid $gray6;
            width: auto;
            min-width: initial; } } }
      .panel-footer {
        padding: 1px 100px 10%;
        .btn {
          width: 100%;
          margin-bottom: 10px; } } } }
  .actions-block {
    .btn {
      width: 100%;
      padding-right: 0;
      padding-left: 0; }
    .btn + .btn {
      margin-top: 5px; } }

  .flowplayer {
    display: block;
    width: initial;
    margin: 10px 12px; } }

// special modal popup content (used for tranfered cases popup)
.modal-content .panel + form .panel .panel-heading {
  background: #fff;
  border-top: 1px solid $gray5;
  color: $black;
  padding-bottom: 0;
  padding-top: 47px; }


// form errors in modal context
.modal-dialog .error-list {
  color: red;
  list-style: none;
  margin: 0;
  padding: 5px 10px 0 0; }
