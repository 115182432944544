
// bootstrap overrides
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: none; }

.table > thead > tr > th {
  border-bottom: none;

  &:last-child {
    padding-right: 20px; } }


// disable bootstrap line on top
.panel > .panel-body + .table, .panel > .panel-body + .table-responsive {
  border-top: none; }




// custom styles
.table > thead > tr > th {
  padding: 0 8px;
  font-weight: normal; }

.table > tbody > tr > td {
  padding: 13px 8px;

  &.align-bottom {
    vertical-align: bottom; } }

.table-big > tbody > tr > td {
  padding: 30px; }

.spacer {
  height: 10px; }

.table-padding-top {
  height: 5px; }

.table-border-top {
  border-top: 1px solid $gray2;
  padding-top: 5px; }


.table {
  border-collapse: initial;
  // margin-top: 10px


  // sortables
  .sortable:after, .asc:after, .desc:after {
    font-family: fontawesome;
    font-size: 13px; }

  .sortable:after {
    content: " \f0dc"; }
  .asc:after {
    content: " \f0de";
    position: relative;
    top: 3px; }
  .desc:after {
    content: " \f0dd";
    position: relative;
    bottom: 3px; }



  // padding only works on td and th (cell elements)
  thead {
    th {
      color: $gray4;
      border-left: 1px solid $gray4;
      &.first {
        border-left: none; }
      &.left-space {
        width: $leftPadding1 - 10px;
        border-left: none; }

      &>a, &>a:active, &>a:focus {
        color: $gray4;
        text-decoration: none; }

      &>a:hover, &>a.asc, &>a.desc {
        color: $black;
        text-decoration: none; } } }

  ul {
    list-style: none outside none;
    padding: 0; } }

// special table for transfered cases in modal popup context
.modal-content .table.detail-list-table {
  tr {
    th, td {
      padding: 4px; } } }

// virtual table
.asTable, .childAsTable > div {
  display: table; }
.asTr {
  display: table-row; }
.asTd {
  display: table-cell; }

.objectives-list .childAsTable {
  &> div {
    margin: 0 0 20px;
    width: 100%; }
  .asTd {
    padding: 5px 0; }
  .col-1 {
    width: auto; }
  .col-2 {
    width: auto; }
  .col-3 {
    padding: 0 0 0 20px;
    width: 100%; }
  // special row divider
  &.with-row-divider > div:before {
    background: $gray2;
    content: '';
    display: block;
    height: 1px;
    margin: 5px 0 15px; }
  &.with-row-divider > div:first-child:before {
    display: none; } }

// table row divider
table tbody tr td.divider {
  padding-right: 0;
  padding-left: 0;
  vertical-align: middle;
  &:after {
    background: $gray2;
    content: '';
    display: block;
    height: 1px; } }

// table without left/right spacing
.table.no-lr-padding {
  thead,
  tbody {
    th:first-child,
    td:first-child {
      padding-left: 0; }
    th:last-child,
    td:last-child {
      padding-right: 0; } } }
