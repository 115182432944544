
// LOGIN VIEW
.login {
  width: $defaultInputWidth + 30;
  display: block;
  margin: auto;



  .panel-heading {
    h3 {
      padding-top: 30px;
      padding-left: 15px;
      margin: 0; } }

  .panel-body {
    padding-top: 10px;

    .welcome {
      padding-bottom: 30px; } }

  .form-horizontal {
    .form-group {
      margin: 0;
      padding-bottom: 20px;
      top: auto; }

    .form-control {
      float: left;
      padding-left: 10px;
      padding-top: 4px;
      padding-bottom: 4px; }

    .has-feedback {
      .form-control-feedback {
        right: 0;
        top: auto;
        width: 30px;
        height: 30px;
        line-height: 30px; } } } }

.modal-content .login {
  width: auto;

  h3 {
    font-size: 20px;
    padding: 0; } }


// NEW PASSWORD VIEW
.password-reset {
  padding-left: $leftPadding1;

  form {
    padding-top: 30px;

    input {
      display: block;
      width: $defaultInputWidth;
      padding: 4px 10px;
      height: 28px; }

    .actions {
      padding-top: 20px; } } }
