// PAGINATION
.pagination_wrapper {
  float: right;
  padding-right: 25px;

  .pagination {
    float: left;
    margin: 0;

    // pagination links
    li {
      a {
        border: none;
        color: $gray4;

        &:hover {
          background: white;
          color: $black; } }

      // focus states
      a:focus, span:focus {
        background: white;
        color: $black; }

      // prev/next icons
      .fa {
        font-weight: bold; } }

    // active state
    &> .active {
      &> a, a:hover, a:focus, span, span:hover, span:focus {
        background: white;
        color: $guideBlue2;
        font-weight: bold;
        border: none; } }

    // disabled state
    &> .disabled {
      &> span, &> span:hover, &> span:focus, &> a, &> a:hover, &> a:focus {
        border: none;
        cursor: default; } } }

  .pagination_range {
    float: left; } }

// "TERMINE"
.schedule-group-appointments .list-group-item {
  background: transparent; }

// "LETZTE AKTIVITAETEN"
a.history-entry-action {
  padding-right: 10px; }
