
.new-client {
  // HEADLINE
  .panel-heading.content-headline {
    @extend .default-content-headline; }

  .panel-heading {
    h4, h3 {
      padding-left: $leftPadding1;
      margin-bottom: 10px; } }

  .panel-body {
    padding: 20px 15px 20px $leftPadding1; }

  .panel > .panel-body + .table,
  .panel > .panel-body + .table-responsive {
    border-top: 1px solid $gray2; }

  .panel.panel-default .panel-footer {
    padding-left: $leftPadding1;
    padding-top: 30px; }

  // FORM
  form {
    @extend .default-form;

    .error-list {
      @extend .default-error-list; }

    .form-group {
      label > input[type="text"] {
        margin-left: 15px; } }

    fieldset {
      @extend .default-fieldset; } } }

