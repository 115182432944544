@font-face {
	font-family: 'sirup';
	src: url('../fonts/sirup.eot?669wi9');
	src: url('../fonts/sirup.eot?#iefix669wi9') format('embedded-opentype'), url('../fonts/sirup.woff?669wi9') format('woff'), url('../fonts/sirup.ttf?669wi9') format('truetype'), url('../fonts/sirup.svg?669wi9#sirup') format('svg');
	font-weight: normal;
	font-style: normal; }

[class^="sirup-"], [class*=" sirup-"] {
	font-family: 'sirup';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.sirup-activity:before {
	content: "\e600"; }

.sirup-office:before {
	content: "\e601"; }

.sirup-graph:before {
	content: "\e602"; }

.sirup-folder:before {
	content: "\e603"; }

.sirup-usersettings:before {
	content: "\e604"; }
