$active_border_color: #66afe9;

// SELECT2 BOX
// replacement select box
.select2-container {
  position: relative;
  @include border-radius(4px);
  display: block;
  width: 450px;
  border: 1px solid #bbb;
  height: 30px;
  max-width: 100%;

  .select2-choice {
    border: none;
    height: 28px;
    line-height: 28px; }

  .select2-offscreen {
    // display: none
    visibility: hidden; } }

// dropdown menu
.select2-drop {
  margin-top: -2px;
  .select2-highlighted {
    background-color: $guideBlue1; } }

.select2-drop-active,
.select2-drop.select2-drop-above.select2-drop-active,
.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices,
.select2-container-multi.select2-container-active .select2-choices {
  border-color: $gray6;

  //open
  .select2-dropdown-open .select2-choice {
    border: none; } }
.select2-container.select2-drop-above .select2-choice {
  border: none; }


// overrides for multiselect
.select2-container.select2-container-multi {
  border: none;
  height: auto;
  min-height: 30px;

  .select2-choices {
    position: relative;
    @include border-radius(3px);
    background: #fff;
    border-color: $gray4; }
  &.select2-container-active .select2-choices {
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)); // value copied from bootstrap.css
    border-color: $active_border_color; }

  .select2-search-choice {
    margin: 5px 0 0 5px; } }

.select2-drop.select2-drop-multi {
  border-color: $active_border_color; }
