.kpi-level-danger,.kpi-level-warning,.kpi-level-success {
  border-left: 4px solid red;
  padding-left: 5px; }
.kpi-level-warning {
  border-left-color: yellow; }
.kpi-level-success {
  border-left-color: green; }

.kpi-column {
  padding: 11px 8px;
  white-space: nowrap;
  .loader:after {
    width: 20px;
    height: 20px; }
  span {
    min-width: 20px;
    display: inline-block; } }

.report-collection--item__heading {
  background: #e7f2dc;
  -webkit-print-color-adjust: exact;
  margin-top: 20px;
  padding: 5px 0 5px 20px;
  h5 {
    span {
      font-size: 20px; } } }

.hide-on-print {
  display: none; }

.print-headline {
  background: #e7f2dc!important;
  -webkit-print-color-adjust: exact!important;
  text-align: center;
  font-size: 30px;
  padding: 15px 0px; }

.printlayout {
  margin: auto;
  width: 750px;

  .report-collection--item__heading {
    background: #e7f2dc!important;
    -webkit-print-color-adjust: exact!important; }

  .show-on-print {
    display: block; }

  .is-note {
    display: none; }

  .print-widget-descriptions {
    padding: 0 20px 20px 20px;

    &.kpi-description {
      margin-top: -70px; }

    .show-on-print {
      padding-top: 10px; } }

  .statistics-widget.is-partial {
    padding: 22px 0;
    margin: 0 22px; }

  .widget:nth-child(2n) {
    background: #e7f2dc!important;
    -webkit-print-color-adjust: exact;
    border-bottom: 2px solid #e7f2dc; } }

.statistics-widget.is-partial {
  border: none;

  .statistics-widget--label {
    font-weight: 600!important; } }

.print-preview-button {
  margin-right: 10px;
  position: absolute;
  right: 10px;
  top: 0;
  margin-top: 5px; }

.collection_widget__attachment {
  width: $defaultInputWidth;
  label {
    width: $defaultInputWidth; }
  input {
    width: $defaultInputWidth; } }
