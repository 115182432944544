// bootstrap resets
.panel,
.panel-heading,
.panel-default > .panel-heading {
  border: none;
  padding: 0;
  border-radius: none;
  box-shadow: none;
  clear: both; }

// QUICK MENU
.dashboard {
  padding: 30px 0;
  width: 100%;
  //@extend .horizontal-gradient
  background: $subHeaderColor;
  text-align: center;

  .quickmenu {
    display: inline-block;

    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary:active {
      border: 0 none;
      border-color: red;
      outline: 0 none; }


    .placeholdersjs {
      color: #cccccc; }

    .new-counseling,
    .search-participant {
      float: left;
      width: 250px; }


    .new-counseling {
      margin-right: 20px;
      display: table;
      &>a {
        display: table-cell; } }

    // "Berater suchen"
    .search-participant {
      .input-group {

        &>input {
          // padding-top: 9px
          margin: auto;
          padding: 10px;
          border-right: none;
          height: 40px;
          position: relative;
          @include fourCornerRadius(4px, 0, 0, 4px); }

        .input-group-btn {
          position: relative;
          @include fourCornerRadius(0, 4px, 4px, 0); }
        .btn.btn-primary {
          background: transparent;
          height: $bigButtonHeight;
          padding: 0 15px; } } } } }



// DASHBOARD CONTENT
.dashboard-content {
  .z4 {
    z-index: 4; }   // z-indices
  .z3 {
    z-index: 3; }
  .z2 {
    z-index: 2; }

  overflow: hidden;
  position: relative;


  &>div {
    background: white;
    position: relative;
    @include box-shadow( 0 0 5px $shadowColor ); }

  &>div:last-child {
    border: none; }




  // DASHBOARD PANELS
  // HEADING
  .panel-default {
    .panel-heading {
      padding: 0;
      position: relative;

      // inactive
      a, .icon-header {
        color: $black;
        font-size: 18px;
        margin: 0;
        padding: 15px 15px 10px 20px;
        width: 100%;
        display: inline-block;

        &> span {
          position: relative;
          left: -8px;
          top: -2px;
          color: white;
          font-size: 17px;
          background-color: $guideBlue1; } }

      a:hover {
        text-decoration: none;
        background-color: $guideBlue1;
        color: white;

        &:after {
          @extend .default-arrow-after; }

        .fa, .sirup {
          background: #fff;
          color: $guideBlue1; } } } }

  .panel-heading {
    border-top: 2px solid #ddd;
    background-color: white;
    color: $black;
    padding: 10px 15px;
    width: 100%;
    display: inline-block;

    h1 {
      font-size: 22px;
      float: left;
      margin: 0; } }

  // icons
  .panel-heading .fa, .panel-heading .sirup,
  .rest .fa, .rest .sirup {
    @include verticalGradient2($guideBlue1, $guideBlue1GradientStop);
    @extend .icon-circled; }


  // BODY
  .panel-body, .panel-footer {
    padding-left: $leftPadding1; }

  table > tbody > tr:nth-child(odd) {
    background-color: white; }



  .list-group-item {
    padding-left: $leftPadding1;
    border-bottom: none;

    &>.fa {
      color: $guideGreen3;
      padding-right: 10px; }

    a {
      color: $guideBlue1;
      padding-right: 10px;

      //&:hover
 } }      ////text-decoration: none

  .panel-footer {
    padding-top: 0px;
    padding-bottom: 15px; }


  // DASHBOARD WIDGETS
  #client-widget {
    .list-group {
      a {
        color: $black; } }
    .clickableRow:hover {
      color: white;

      a {
        color: white; } } }

  #calendar-widget {
    .panel-footer {
      padding-left: 0;
      text-align: center; }

    .calendar-event.calendar-event-big.calendar-event-empty {
      margin-bottom: 10px; } }


  #widget-favourite-statistic {
    .statistics-widget {
      border-right: none; } } }

.dashboard-content .list-group-item {
  color: #aaa;
  strong {
    color: #333; }
  .no-wrap {
    max-width: 380px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: left;
    margin-right: 5px; } }

